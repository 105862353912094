<template>
  <div class="home">
    <Header v-if="$route.name != 'Video'" />
    <div :class="$route.name != 'Video' ? 'main' : ''">
      <router-view></router-view>
    </div>
    <Footer v-if="$route.name != 'Download' && $route.name != 'Video'" />
    <GoTop />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import GoTop from "@/components/GoTop.vue";
export default {
  name: "home",
  components: {
    Header,
    Footer,
    GoTop,
  },
  async created() {},
};
</script>
<style lang="scss" scoped>
.main {
  min-height: calc(100vh - 206px);
  padding-top: 84px;
}
</style>
