import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import http from './http';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '../../utils/extension';
import '../../utils/filter';
import '@babel/polyfill';
import 'whatwg-fetch';
import './video';

import plugins from '../../utils/plugins';
Vue.prototype.$pub = plugins;
import MathJax from '../../utils/MathJax'
Vue.prototype.MathJax = MathJax;

Vue.config.productionTip = false;
Vue.config.login_flag = false;

Vue.use(ElementUI);

import Currency from '@/components/Currency.vue';
Vue.component('Currency', Currency);

import Empty from '../../components/Empty.vue';
Vue.component('Empty', Empty);

import VueAwesomeSwiper from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
Vue.use(VueAwesomeSwiper);

import List from '../../components/List.vue';
Vue.component("list", List);

// quill editor
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
Vue.use(VueQuillEditor);

const vue = new Vue({
    router,
    store,
    render: h => h(App),
});

router.beforeEach(async(to, from, next) => {
    if (from.name != 'ForgotPassword' && from.name != 'Login') {
        to.query.relogin = from.name;
    } else {
        to.query.relogin = '';
    }
    // if (
    //     from.name == 'ExamPackagePracticeDo' &&
    //     from.query.action != 'preview' &&
    //     to.name != 'ExamPackagePracticeDo' &&
    //     !from.params.allow
    // ) {
    //     await vue.$promiseConfirm('确定要退出试卷练习？退出后未完成的试卷练习记录将不会保存，是否确定？', {
    //         type: 'warning',
    //     });
    // }
    if (to.meta.requireAuth) {
        if (store.state.token) {
            next();
        } else {
            next({
                name: 'Login',
                query: { relogin: 1 },
            });
        }
    } else {
        next();
    }
    if (store.state.webConfig.web_title) {
        document.title = store.state.webConfig.web_title + (to.meta.title || '');
    } else {
        store.dispatch('getWebConfig', to.meta.title || '');
    }
    store.dispatch('get_user_info');
});

http.beforeRequest = function(isAuthRequest, showLoading) {
    if (isAuthRequest) {
        if (!store.state.token) {
            router.push({ name: 'Login' });
            return false;
        }
    }
    if (showLoading) {
        vue.$loading = ElementUI.Loading.service({
            lock: true,
            text: 'Loading',
            spinner: 'el-icon-loading',
            background: 'transparent',
        });
    }
    return true;
};
http.afterRequest = function() {
    if (vue.$loading && vue.$loading.close) {
        vue.$loading.close();
    }
};
http.onApiError = function(data) {
    if (data.code == 202) {
        if (!Vue.config.login_flag) {
            Vue.config.login_flag = true;
            vue.$error(data.msg).then(() => {
                store.commit('logout');
                router.push({ name: 'Login' });
                Vue.config.login_flag = false;
            });
        }
    } else {
        if (data.msg == "你来晚了哦,名额已经没有了~") {
            vue.$alert(data.msg, "提示");
        } else {
            vue.$message.error(data.msg);
        }
    }
};
Vue.prototype.$http = http;

vue.$mount('#app');