import Vue from 'vue';
import Vuex from 'vuex';
import http from './http';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        webConfig: {},
        token: localStorage.studentToken || '',
        user_info: {},
        keywords: '',
        hasNewMessage: false,
        commonSetting: {
            watch_live_type: 3,
            switch_vip_module: 1,
            switch_study_module: 1,
            switch_question_module: 1,
            homework_module: {},
            point_info: {}
        },
        showLoginModal: false,
        liveSelect: {
            show: false,
            client_url: "",
            web_url: ""
        },
        breads: sessionStorage.stuBreads ? JSON.parse(sessionStorage.stuBreads) : [],
        search: ""
    },
    mutations: {
        setWebConfig(state, config) {
            state.webConfig = config;
        },
        setCommonSetting(state, setting) {
            state.commonSetting = setting;
        },
        login(state, token) {
            state.token = token;
            localStorage.studentToken = token;
        },
        setUser(state, user_info) {
            state.user_info = user_info;
        },
        logout(state) {
            state.token = '';
            state.user_info = {};
            localStorage.removeItem('studentToken');
        },
        search(state, keywords) {
            state.keywords = keywords;
        },
        setMessageState(state, messageState) {
            state.hasNewMessage = messageState;
        },
        showLogin(state, flag) {
            state.showLoginModal = flag;
        },
        showSelectLive(state, liveSelect) {
            state.liveSelect = liveSelect;
        },
        setBreads(state, route) {
            const bread = {
                title: route.meta.title,
                name: route.name,
                query: route.query,
                params: route.params
            };
            if (route.meta.root) {
                state.breads = [bread];
            } else {
                // 如果在面包屑链中，直接跳至该面包屑
                let existBread = false;
                for (let i = 0; i < state.breads.length; ++i) {
                    if (state.breads[i].name == bread.name) {
                        existBread = true;
                        state.breads.splice(i + 1);
                        break;
                    }
                }
                if (!existBread) {
                    state.breads.push(bread);
                }
            }
            sessionStorage.stuBreads = JSON.stringify(state.breads);
        },
        setSearch(state, search) {
            state.search = search;
        }
    },
    actions: {
        get_user_info: async context => {
            if (!context.state.token) {
                return;
            }
            let data = await http.get('/api/app/userInfo');
            data.isLogin = true;
            data.sex = data.sex.toString();
            context.commit('setUser', data);
            localStorage.setItem('user_id',data.id);
        },
        getWebConfig: async function({ commit }, title) {
            let config = await http.get('/api/app/get/config/system_webConfig');
            commit('setWebConfig', config);
            document.title = config.web_title + title;
            // 网站关键词
            let meta_keywords = document.createElement('meta');
            meta_keywords.name = 'keywords';
            meta_keywords.content = config.web_keywords;
            document.getElementsByTagName('head')[0].appendChild(meta_keywords);
            // 网站描述
            let meta_description = document.createElement('meta');
            meta_description.name = 'description';
            meta_description.content = config.web_description;
            document.getElementsByTagName('head')[0].appendChild(meta_description);
            let setting = await http.get('/api/app/webSetting');
            setting.switch_question_module = parseInt(setting.switch_question_module);
            setting.switch_study_module = parseInt(setting.switch_study_module);
            setting.switch_vip_module = parseInt(setting.switch_vip_module);
            setting.watch_live_type = parseInt(setting.watch_live_type);
            commit("setCommonSetting", setting);

            let link = document.querySelector("link[rel*='icon']") || document.createElement('link');
            link.type = 'image/x-icon';
            link.rel = 'shortcut icon';
            link.href = config.web_ico;
            document.getElementsByTagName('head')[0].appendChild(link);
        },
        async refreshMessageState(content) {
            let data = await http.get('/api/app/message/isRead');
            content.commit('setMessageState', data.is_read == 0);
        },
    },
});
