<template>
  <div class="header header-bar">
    <table>
      <tr>
        <td>
          <div class="main-container">
            <router-link :to="{ name: 'Home' }">
              <img class="logo" :src="$store.state.webConfig.web_logo" />
            </router-link>
            <ul class="nav-list" v-if="!teacher">
              <li
                @click="goTo(nav)"
                v-for="nav in topFiveNavigates"
                :key="nav.id"
              >
                <a
                  :class="{
                    'router-link-active': $route.path.indexOf(nav.url) >= 0,
                  }"
                >
                  <span>{{ nav.name }}</span>
                </a>
                <ul
                  class="nav-child"
                  v-if="nav.children && nav.children.length"
                >
                  <li
                    class="nav-child-item"
                    v-for="child in nav.children"
                    :key="child.id"
                    @click.stop
                  >
                    <a
                      :target="child.is_target == 1 ? '_blank' : '_self'"
                      :href="child.url"
                    >
                      <span>{{ child.name }}</span>
                    </a>
                  </li>
                </ul>
              </li>
              <li v-if="navigates.length" class="li-more">
                <el-dropdown>
                  <span class="el-dropdown-link">
                    发现更多
                    <i class="el-icon-caret-bottom"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item v-for="nav in navigates" :key="nav.id">
                      <a @click="goTo(nav)">
                        <span>{{ nav.name }}</span>
                      </a>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </li>
            </ul>
            <div class="userinfo" :class="{ 'userinfo-teacher': teacher }">
              <div v-if="teacher" class="lnk-login">
                <el-button
                  type="primary"
                  size="small"
                  v-if="$store.state.user_info.is_assistant == 1"
                  @click="changeIndentity(7)"
                  style="margin-right: 15px"
                  >切换助教</el-button
                >
                <el-button
                  type="primary"
                  size="small"
                  v-if="$store.state.user_info.is_teacher == 1"
                  @click="changeIndentity(5)"
                  style="margin-right: 15px"
                  >切换讲师</el-button
                >
                <el-dropdown @command="onTeacherMenu">
                  <img
                    class="avatar"
                    v-if="$store.state.token"
                    :src="$store.state.user_info.avatar"
                  />
                  <span class="name">{{
                    $store.state.user_info.real_name
                  }}</span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="logout"
                      >退出登录</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <div v-else>
                <el-dropdown
                  class="f-left"
                  v-if="$store.state.token"
                  @command="onStudentMenu"
                >
                  <router-link class="lnk-login" :to="{ name: 'MemberStudy' }">
                    <img class="avatar" :src="$store.state.user_info.avatar" />
                    <span style="color: #333">{{
                      $store.state.user_info.nickname
                    }}</span>
                  </router-link>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="center"
                      >个人中心</el-dropdown-item
                    >
                    <el-dropdown-item command="logout"
                      >退出登录</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
                <a
                  href="javascript:void(0)"
                  class="lnk-login"
                  v-else
                  @click="$router.push({ name: 'Login' })"
                >
                  <span class="register">注册</span>
                  <span class="line">|</span>登录
                </a>
                <a
                  class="organization-join"
                  target="_blank"
                  href="http://ac.zhanggongedu.com/"
                >
                  &nbsp;&nbsp;机构入驻
                </a>
              </div>
            </div>
            <div class="search" v-if="!teacher">
              <input
                class="search-input"
                type="text"
                v-model="keywords"
                placeholder="课程名称关键字搜索"
                @keydown.enter="search"
              />
              <span class="search-btn">
                <img
                  class="search-btn-img"
                  src="../assets/images/icon_head_search.png"
                  alt="搜索"
                  @click="search"
                />
              </span>
            </div>
            <div style="clear: both"></div>
          </div>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
export default {
  name: "Header",
  props: {
    teacher: {
      default: false,
    },
  },
  data() {
    return {
      navigates: [],
      topFiveNavigates: [],
      keywords: "",
    };
  },
  watch: {
    $route: function () {
      this.keywords = this.$route.query.k || "";
    },
  },
  async created() {
    let navigates = await this.$http.get("/api/app/navigator");
    this.topFiveNavigates = navigates.splice(0, 5);
    this.navigates = navigates;
  },
  methods: {
    onTeacherMenu(command) {
      // console.log("onTeacherMenu", command);
      switch (command) {
        case "logout":
          {
            this.$store.commit("logout");
            this.$router.push({
              name: "Login",
            });
          }
          break;
      }
    },
    search() {
      this.$store.commit("setSearch", this.keywords);
      this.$router.push({ name: "Course" });
    },
    onStudentMenu(command) {
      switch (command) {
        case "center":
          this.$router.push({ name: "MemberStudy" });
          break;
        case "logout":
          this.$store.commit("logout");
          this.$router.push({ name: "Course" });
          break;
      }
    },
    goTo(nav) {
      let target = nav.is_target == 1 ? "_blank" : "_self";
      if (nav.is_outer == 1) {
        window.open(nav.url, target);
      } else {
        this.$store.commit("setSearch", "");
        this.keywords = "";
        if (this.$route.path == nav.url) {
          this.$router.go(0);
        } else {
          this.$router.push({ path: nav.url });
        }
      }
    },
    async changeIndentity(type) {
      let data = await this.$http.post("/api/app/login", {
        mobile: this.$store.state.user_info.mobile,
        type: type,
      });
      if (type == 5) {
        localStorage.teacherToken = data.remember_token;
        window.open("/teacher/");
      } else if (type == 7) {
        localStorage.assistToken = data.remember_token;
        window.open("/assist/");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/varibles.scss";

.main-container {
  display: inline-block;
  min-width: 1200px;
  width: auto;
  height: 84px;
}

.header {
  height: 84px;
  line-height: 84px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #fff;
  width: 100%;
  z-index: 999;
  box-shadow: 0 3px 6px 0 rgba(33, 24, 21, 0.08);
  overflow: hidden;
}

table {
  width: 100%;
  td {
    text-align: center;
  }
}

.logo {
  float: left;
  max-height: 45px;
  margin-top: 19px;
}

.userinfo {
  float: right;
  position: relative;
  &.userinfo-teacher {
    flex: 1;
    .lnk-login {
      float: right;
    }
  }
  > div {
    display: flex;
  }
  .menus {
    position: absolute;
    top: 80px;
    right: 5px;
    width: 140px;
    opacity: 0;
    /deep/ .el-card__body {
      padding: 0;
    }
    li {
      font-size: 15px;
      line-height: 40px;
      text-align: center;
      cursor: pointer;
      &:hover {
        background-color: $primary-color-light;
      }
    }
  }
  .lnk-login:hover > .menus {
    top: 70px;
    opacity: 255;
  }
}

.userinfo .lnk-login {
  float: left;
  font-size: 16px;
  color: $primary-color;
  height: 84px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  .line {
    margin: 0 5px;
  }
  .register,
  .line {
    color: #595959;
  }
}
.organization-join {
  color: #595959;
}
.userinfo .lnk-login:first-child {
  margin-left: 15px;
}

.search {
  position: relative;
  width: 206px;
  height: 32px;
  line-height: 30px;
  border: 1px solid #e6e6e6;
  border-radius: 3px;
  float: right;
  margin-top: 25px;
  overflow: hidden;
}

.search-input {
  padding: 0 30px 0 15px;
  width: 100%;
  background: none;
  height: 30px;
  line-height: 30px;
  border: none;
  outline: none;
  font-size: 14px;
  color: #333;
}

.search-btn {
  display: block;
  position: absolute;
  right: 10px;
  top: 7px;
  line-height: 16px;
  cursor: pointer;
}

.search-btn-img {
  width: 16px;
  height: 16px;
}

.header-bar .nav-list {
  float: left;
  margin-left: 52px;
  display: flex;
}

.header-bar .nav-list li {
  float: left;
  padding-right: 59px;
  cursor: pointer;
  position: relative;
  white-space: nowrap;
  a {
    display: block;
  }
}

@media screen and (max-width: 1600px) {
  .header-bar .nav-list li {
    padding-right: 49px;
  }
  .search {
    width: 180px;
  }
}

@media screen and (max-width: 1440px) {
  .header-bar .nav-list li {
    padding-right: 44px;
  }
  .li-more {
    width: 42px !important;
    line-height: 42px !important;
    /deep/ .el-dropdown {
      height: 42px !important;
      box-sizing: border-box;
      margin-top: 20px;
    }
  }
}

@media screen and (max-width: 1280px) {
  .header-bar .nav-list li {
    padding-right: 39px;
  }
}

.header-bar .nav-list li span {
  font-size: 18px;
  color: #333;
}

.header-bar .nav-list .router-link-active span {
  color: $primary-color;
}

.header-bar li:hover > a > span {
  color: $primary-color;
}

.header-bar .nav-list > li:hover .nav-child {
  display: block;
}

.header-bar .nav-child {
  position: absolute;
  border: 1px solid #ddd;
  left: 0;
  background-color: white;
  width: 100%;
  text-align: center;
  display: none;
  border-radius: 5px;
}

.header-bar .nav-child li {
  float: none;
  white-space: nowrap;
  border-bottom: 1px solid #ddd;
}

.header-bar .nav-child-item {
  line-height: 40px;
  padding-right: 0 !important;
}

.avatar {
  width: 40px;
  height: 40px;
  line-height: 40px;
  vertical-align: middle;
  margin-right: 5px;
  border-radius: 100%;
}

.name {
  font-size: 16px;
  color: rgba(0, 0, 0, 0.85);
}

.nickname {
  color: #333;
}

.li-more {
  padding-right: 0 !important;
  margin-right: 0;
}

.dropdown-point {
  writing-mode: vertical-rl;
}
</style>
