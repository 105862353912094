import Vue from "vue";
import Router from "vue-router";
import Home from "./views/Home.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "Home",
      redirect: "/course",
      component: Home,
      children: [
        {
          path: "/about",
          name: "About",
          component: () =>
            import(/* webpackChunkName: "common" */ "./views/About.vue"),
        },
        {
          path: "/show-teachers",
          name: "ShowTeachers",
          component: () =>
            import(/* webpackChunkName: "common" */ "./views/ShowTeachers.vue"),
        },
        {
          path: "/show-teachers/:id",
          name: "ShowTeachersDetaial",
          component: () =>
            import(
              /* webpackChunkName: "common" */ "./views/oto/OtoTeacherShow.vue"
            ),
        },
        {
          path: "/course",
          name: "Course",
          component: () =>
            import(
              /* webpackChunkName: "course" */ "./views/course/Course.vue"
            ),
        },
        {
          path: "/course-list",
          name: "CourseList",
          component: () =>
            import(
              /* webpackChunkName: "course-list" */ "./views/course/CourseList.vue"
            ),
        },

        {
          path: "/course/coupon",
          name: "CourseCoupon",
          component: () =>
            import(
              /* webpackChunkName: "course" */ "./views/course/CourseCoupon.vue"
            ),
        },
        {
          path: "/course/:id",
          name: "CourseShow",
          component: () =>
            import(
              /* webpackChunkName: "course" */ "./views/course/CourseShow.vue"
            ),
        },
        {
          path: "/course/:id/cloud_class",
          name: "CloudClassNavigation",
          component: () =>
            import(
              /* webpackChunkName: "course" */ "./views/course/CloudClassNavigation.vue"
            ),
        },

        {
          path: "/video",
          name: "Video",
          component: () =>
            import(/* webpackChunkName: "course" */ "./views/course/Video.vue"),
          meta: {
            requireAuth: true,
          },
        },

        {
          path: "/cloud_class",
          name: "CloudClass",
          component: () =>
            import(
              /* webpackChunkName: "course" */ "./views/course/CloudClass.vue"
            ),
          meta: {
            requireAuth: true,
          },
        },

        {
          path: "/publicCoursePlay",
          name: "PublicCoursePlay",
          component: () =>
            import(
              /* webpackChunkName: "course" */ "./views/course/PublicCoursePlay.vue"
            ),
        },
        {
          path: "/oto",
          name: "Oto",
          component: () =>
            import(/* webpackChunkName: "oto" */ "./views/oto/Oto.vue"),
        },
        {
          path: "/oto/coupon",
          name: "OtoCoupon",
          component: () =>
            import(/* webpackChunkName: "oto" */ "./views/oto/OtoCoupon.vue"),
        },
        {
          path: "/oto/:id",
          name: "OtoTeacherShow",
          component: () =>
            import(
              /* webpackChunkName: "oto" */
              "./views/oto/OtoTeacherShow.vue"
            ),
        },
        {
          path: "/news",
          name: "News",
          component: () =>
            import(/* webpackChunkName: "news" */ "./views/news/News.vue"),
        },
        {
          path: "/news/:id",
          name: "NewsShow",
          component: () =>
            import(/* webpackChunkName: "news" */ "./views/news/NewsShow.vue"),
        },
        {
          path: "/question",
          name: "Question",
          component: () =>
            import(
              /* webpackChunkName: "question" */ "./views/question/Question.vue"
            ),
        },
        {
          path: "/question-show/:id",
          name: "QuestionShow",
          component: () =>
            import(
              /* webpackChunkName: "question" */ "./views/question/QuestionShow.vue"
            ),
        },
        {
          path: "/question-pay/:ask_id/:id",
          name: "QuestionPay",
          component: () =>
            import(
              /* webpackChunkName: "questionPay" */ "./views/question/QuestionPay.vue"
            ),
        },
        {
          path: "/question-success/:ask_id/:id",
          name: "QuestionSuccess",
          component: () =>
            import(
              /* webpackChunkName: "questionSuccess" */
              "./views/question/QuestionSuccess.vue"
            ),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: "/order-confirm/:id",
          name: "OrderConfirm",
          component: () =>
            import(
              /* webpackChunkName: "order" */
              "./views/order/OrderConfirm.vue"
            ),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: "/order-pay/:id",
          name: "OrderPay",
          component: () =>
            import(
              /* webpackChunkName: "order" */
              "./views/order/OrderPay.vue"
            ),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: "/order-success/:id",
          name: "OrderSuccess",
          component: () =>
            import(
              /* webpackChunkName: "order" */
              "./views/order/OrderSuccess.vue"
            ),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: "/vip",
          name: "MemberVip",
          component: () =>
            import(
              /* webpackChunkName: "member" */
              "./views/member/MemberVip.vue"
            ),
        },
        {
          path: "/contact",
          name: "ContactUs",
          component: () =>
            import(/* webpackChunkName: "common" */ "./views/ContactUs.vue"),
        },
        {
          path: "/help",
          name: "HelpCenter",
          component: () =>
            import(/* webpackChunkName: "common" */ "./views/HelpCenter.vue"),
        },
        {
          path: "/exam",
          name: "ExamIndex",
          component: () =>
            import(/* webpackChunkName: "exam" */ "./views/exam/ExamIndex.vue"),
        },
        {
          path: "/exam/package",
          name: "ExamPackagePractice",
          component: () =>
            import(
              /* webpackChunkName: "exam" */ "./views/exam/ExamPackagePractice.vue"
            ),
          meta: {
            requireAuth: false,
          },
        },
        {
          path: "/exam/package/des",
          name: "ExamPackagePracticeDes",
          component: () =>
            import(
              /* webpackChunkName: "exam" */ "./views/exam/ExamPackagePracticeDes.vue"
            ),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: "/exam/package/do",
          name: "ExamPackagePracticeDo",
          component: () =>
            import(
              /* webpackChunkName: "exam" */ "./views/exam/ExamPackagePracticeDo.vue"
            ),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: "/exam/package/score",
          name: "ExamPackagePracticeScore",
          component: () =>
            import(
              /* webpackChunkName: "exam" */ "./views/exam/ExamPackagePracticeScore.vue"
            ),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: "/exam/package/analysis",
          name: "ExamPackagePracticeAnalysis",
          component: () =>
            import(
              /* webpackChunkName: "exam" */ "./views/exam/ExamPackagePracticeAnalysis.vue"
            ),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: "/exam/simulation",
          name: "ExamSimulation",
          component: () =>
            import(
              /* webpackChunkName: "exam" */ "./views/exam/ExamSimulation.vue"
            ),
          meta: {
            requireAuth: false,
          },
        },
        {
          path: "/exam/point",
          name: "ExamPoint",
          component: () =>
            import(/* webpackChunkName: "exam" */ "./views/exam/ExamPoint.vue"),
          meta: {
            requireAuth: false,
          },
        },
        {
          path: "/exam/simulation/des/:id",
          name: "ExamSimulationDes",
          component: () =>
            import(
              /* webpackChunkName: "exam" */ "./views/exam/ExamSimulationDes.vue"
            ),
          meta: {
            requireAuth: false,
          },
        },
        {
          path: "/exam/point/do",
          name: "ExamPointDo",
          component: () =>
            import(
              /* webpackChunkName: "exam" */ "./views/exam/ExamPointDo.vue"
            ),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: "/exam/point/report",
          name: "ExamPointReport",
          component: () =>
            import(
              /* webpackChunkName: "exam" */ "./views/exam/ExamPointReport.vue"
            ),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: "/exam/point/analysis",
          name: "ExamPointAnalysis",
          component: () =>
            import(
              /* webpackChunkName: "exam" */ "./views/exam/ExamPointAnalysis.vue"
            ),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: "/consolidate",
          name: "Consolidate",
          component: () =>
            import(/* webpackChunkName: "consolidate" */ "./views/Consolidate"),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: "/download",
          name: "Download",
          component: () =>
            import(/* webpackChunkName: "download" */ "./views/Download.vue"),
        },
        {
          path: "/book",
          name: "Book",
          component: () =>
            import(/* webpackChunkName: "book" */ "./views/book/Book.vue"),
        },
        {
          path: "/book/coupon",
          name: "BookCoupon",
          component: () =>
            import(
              /* webpackChunkName: "book" */ "./views/book/BookCoupon.vue"
            ),
        },
        {
          path: "/book/:id",
          name: "BookDetail",
          component: () =>
            import(
              /* webpackChunkName: "book" */ "./views/book/BookDetail.vue"
            ),
        },
        {
          path: "/integral/goods/:id",
          name: "IntegralGoodsDetail",
          component: () =>
            import(
              /* webpackChunkName: "integral" */
              "./views/integral/IntegralGoodsDetail.vue"
            ),
        },
        {
          path: "/exercise",
          name: "Exercise",
          component: () =>
            import(
              /* webpackChunkName: "login" */ "./views/exercise/Exercise.vue"
            ),
          meta: {
            title: "章节练习",
          },
        },
        {
          path: "/exercise/:id",
          name: "ExerciseShow",
          component: () =>
            import(
              /* webpackChunkName: "login" */ "./views/exercise/ExerciseShow.vue"
            ),
          meta: {
            title: "章节练习",
          },
        },
        {
          path: "/exercise/package/do",
          name: "ExamExerciseDo",
          component: () =>
            import(
              /* webpackChunkName: "exam" */ "./views/exam/ExamExerciseDo.vue"
            ),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: "/exercise/package/report",
          name: "ExamExerciseReport",
          component: () =>
            import(
              /* webpackChunkName: "exam" */ "./views/exam/ExamExerciseReport.vue"
            ),
          meta: {
            requireAuth: true,
          },
        },
        {
          path: "/exercise/package/analysis",
          name: "ExamExerciseAnalysis",
          component: () =>
            import(
              /* webpackChunkName: "exam" */ "./views/exam/ExamExerciseAnalysis.vue"
            ),
          meta: {
            requireAuth: true,
          },
        },
      ],
    },
    {
      path: "/member",
      name: "Member",
      component: () =>
        import(/* webpackChunkName: "member" */ "./views/member/Member.vue"),
      meta: {
        requireAuth: true,
      },
      children: [
        {
          path: "/member/oto",
          name: "MemberOto",
          component: () =>
            import(
              /* webpackChunkName: "member" */
              "./views/member/MemberOto.vue"
            ),
          meta: {
            requireAuth: true,
            bread: [{ title: "我的约课" }],
            title: "我的约课",
            root: true,
          },
        },
        {
          path: "/member/order",
          name: "MemberOrder",
          component: () =>
            import(
              /* webpackChunkName: "member" */
              "./views/member/MemberOrder.vue"
            ),
          meta: {
            requireAuth: true,
            bread: [{ title: "我的订单" }],
            title: "我的订单",
            root: true,
          },
        },
        {
          path: "/member/order_detail",
          name: "MemberOrderDetail",
          component: () =>
            import(
              /* webpackChunkName: "member" */
              "./views/member/MemberOrderDetail.vue"
            ),
          meta: {
            requireAuth: true,
            bread: [{ title: "我的订单" }, { title: "订单详情" }],
            title: "订单详情",
            // root: true
          },
        },
        {
          path: "/member/address",
          name: "MemberAddress",
          component: () =>
            import(
              /* webpackChunkName: "member" */
              "./views/member/MemberAddress.vue"
            ),
          meta: {
            requireAuth: true,
            bread: [{ title: "收货地址" }],
            title: "收货地址",
            root: true,
          },
        },
        {
          path: "/member/oto/communication",
          name: "MemberOtoCommunication",
          component: () =>
            import(
              /* webpackChunkName: "member" */
              "./views/member/MemberOtoCommunication.vue"
            ),
          meta: {
            requireAuth: true,
            title: "沟通记录",
          },
        },
        {
          path: "/member/profile",
          name: "MemberProfile",
          component: () =>
            import(
              /* webpackChunkName: "member" */
              "./views/member/MemberProfile.vue"
            ),
          meta: {
            requireAuth: true,
            bread: [{ title: "个人中心" }],
            title: "个人中心",
            root: true,
          },
        },
        {
          path: "/member/collect",
          name: "MemberCollect",
          component: () =>
            import(
              /* webpackChunkName: "member" */
              "./views/member/MemberCollect.vue"
            ),
          meta: {
            requireAuth: true,
            bread: [{ title: "我的收藏" }],
            title: "我的收藏",
            root: true,
          },
        },
        {
          path: "/member/wealth",
          name: "MemberWealth",
          component: () =>
            import(
              /* webpackChunkName: "member" */
              "./views/member/MemberWealth.vue"
            ),
          meta: {
            requireAuth: true,
            bread: [{ title: "我的财富" }],
            title: "我的财富",
            root: true,
          },
        },
        {
          path: "/member/account",
          name: "MemberAccount",
          component: () =>
            import(
              /* webpackChunkName: "member" */
              "./views/member/MemberAccount.vue"
            ),
          meta: {
            requireAuth: true,
            bread: [{ title: "账户余额" }],
            title: "账户余额",
            root: true,
          },
        },
        {
          path: "/member/study",
          name: "MemberStudy",
          component: () =>
            import(
              /* webpackChunkName: "member" */
              "./views/member/MemberStudy.vue"
            ),
          meta: {
            requireAuth: true,
            bread: [{ title: "我的学习" }],
            title: "我的学习",
            root: true,
          },
        },
        {
          path: "/member/exercise",
          name: "MemberExercise",
          component: () =>
            import(
              /* webpackChunkName: "member" */
              "./views/member/MemberExercise.vue"
            ),
          meta: {
            requireAuth: true,
            bread: [{ title: "我的练习" }],
            title: "我的练习",
            root: true,
          },
        },
        {
          path: "/member/exam",
          name: "MemberExam",
          component: () =>
            import(
              /* webpackChunkName: "member" */
              "./views/member/MemberExam.vue"
            ),
          meta: {
            requireAuth: true,
            bread: [{ title: "我的考试" }],
            title: "我的考试",
            root: true,
          },
        },
        {
          path: "/member/question",
          name: "MemberQuestion",
          component: () =>
            import(
              /* webpackChunkName: "MemberQuestion" */
              "./views/member/MemberQuestion.vue"
            ),
          meta: {
            requireAuth: true,
            bread: [{ title: "我的问答" }],
            title: "我的问答",
            root: true,
          },
        },
        {
          path: "/member/path",
          name: "MemberPath",
          component: () =>
            import(
              /* webpackChunkName: "MemberPath" */
              "./views/member/MemberPath.vue"
            ),
          meta: {
            requireAuth: true,
            bread: [{ title: "学习轨迹" }],
            title: "学习轨迹",
            root: true,
          },
        },
        {
          path: "/member/error_list",
          name: "MemberErrorList",
          component: () =>
            import(
              /* webpackChunkName: "MemberErrorList" */
              "./views/member/MemberErrorList.vue"
            ),
          meta: {
            requireAuth: true,
            bread: [{ title: "错题本" }],
            title: "错题本",
            root: true,
          },
        },
        {
          path: "/member/evaluation",
          name: "MemberEvaluation",
          redirect: { name: "MemberEvaluationTest" },
          component: () =>
            import(
              /* webpackChunkName: "member" */
              "./views/member/MemberEvaluation.vue"
            ),
          children: [
            {
              path: "/member/evaluation/test",
              name: "MemberEvaluationTest",
              title: "我的测评",
              root: true,
              component: () =>
                import(
                  /* webpackChunkName: "member" */
                  "./views/member/MemberEvaluationTest.vue"
                ),
              meta: {
                requireAuth: true,
                bread: [{ title: "测评记录" }],
                title: "测评记录",
                root: true,
              },
            },
            {
              path: "/member/evaluation/mistake",
              name: "MemberEvaluationMistake",
              component: () =>
                import(
                  /* webpackChunkName: "member" */
                  "./views/member/MemberEvaluationMistake.vue"
                ),
              meta: {
                requireAuth: true,
                bread: [{ title: "错题记录" }],
                title: "错题记录",
                root: true,
              },
            },
            {
              path: "/member/evaluation/collect",
              name: "MemberEvaluationCollect",
              component: () =>
                import(
                  /* webpackChunkName: "member" */
                  "./views/member/MemberEvaluationCollect.vue"
                ),
              meta: {
                requireAuth: true,
                bread: [{ title: "习题收藏" }],
                title: "习题收藏",
                root: true,
              },
            },
          ],
        },
        {
          path: "/member/evaluation/detail",
          name: "MemberEvaluationDetail",
          component: () =>
            import(
              /* webpackChunkName: "member" */
              "./views/member/MemberEvaluationDetail.vue"
            ),
          meta: {
            requireAuth: true,
            bread: [
              { title: "习题收藏", route: "/member/evaluation/collect" },
              { title: "习题详情" },
            ],
            title: "习题详情",
          },
        },
        {
          path: "/member/calendar",
          name: "MemberCalendar",
          component: () =>
            import(
              /* webpackChunkName: "member" */
              "./views/member/MemberCalendar.vue"
            ),
          meta: {
            requireAuth: true,
            bread: [{ title: "学习日历" }],
            title: "学习日历",
            root: true,
          },
        },
        {
          path: "/member/questionExamDetail",
          name: "MemberQuestionExamDetail",
          component: () =>
            import(
              /* webpackChunkName: "member" */
              "./views/member/MemberQuestionExamDetail.vue"
            ),
          meta: {
            requireAuth: true,
            bread: [{ title: "消息中心" }, { title: "题目问答" }],
            title: "题目问答",
            // root: true
          },
        },
        {
          path: "/member/message",
          name: "MemberMessage",
          component: () =>
            import(
              /* webpackChunkName: "member" */
              "./views/member/MemberMessage.vue"
            ),
          meta: {
            requireAuth: true,
            bread: [{ title: "消息中心" }],
            title: "消息中心",
            root: true,
          },
        },
        {
          path: "/member/study/homework",
          name: "MemberCourseHomework",
          component: () =>
            import(
              /* webpackChunkName: "member" */
              "./views/member/MemberHomework.vue"
            ),
          meta: {
            requireAuth: true,
            bread: [{ title: "课程作业列表" }],
            title: "课程作业列表",
          },
        },
        {
          path: "/member/homework",
          name: "MemberHomework",
          component: () =>
            import(
              /* webpackChunkName: "member" */
              "./views/member/MemberHomework.vue"
            ),
          meta: {
            requireAuth: true,
            bread: [{ title: "我的作业" }],
            title: "我的作业",
            root: true,
          },
        },
        {
          path: "/member/homework/detail",
          name: "MemberHomeworkDetail",
          component: () =>
            import(
              /* webpackChunkName: "member" */
              "./views/member/MemberHomeworkDetail.vue"
            ),
          meta: {
            requireAuth: true,
            bread: [{ title: "作业详情" }],
            title: "作业详情",
          },
        },
        {
          path: "/member/homework/do",
          name: "MemberHomeworkDo",
          component: () =>
            import(
              /* webpackChunkName: "member" */
              "./views/member/MemberHomeworkDo.vue"
            ),
          meta: {
            requireAuth: true,
            bread: [{ title: "作业答题" }],
            title: "作业答题",
          },
        },
        {
          path: "/member/homework/preview",
          name: "MemberHomeworkPreview",
          component: () =>
            import(
              /* webpackChunkName: "member" */
              "./views/member/MemberHomeworkPreview.vue"
            ),
          meta: {
            requireAuth: true,
            bread: [{ title: "作业预览" }],
            title: "作业预览",
          },
        },
        {
          path: "/member/integral",
          name: "MemberIntegral",
          component: () =>
            import(
              /* webpackChunkName: "member" */
              "./views/member/integral/MemberIntegral.vue"
            ),
          meta: {
            requireAuth: true,
            title: "我的积分",
            root: true,
          },
        },
      ],
    },
    {
      path: "/download-client",
      name: "DownloadClient",
      component: () =>
        import(
          /* webpackChunkName: "download-client" */ "@/components/DownloadClient"
        ),
      meta: {
        title: "直播客户端",
      },
    },
    {
      path: "/login",
      name: "Login",
      component: () =>
        import(/* webpackChunkName: "login" */ "./views/Login.vue"),
    },
    {
      path: "/forget-password",
      name: "ForgotPassword",
      component: () =>
        import(/* webpackChunkName: "login" */ "./views/ForgotPassword.vue"),
    },
    {
      path: "/contract",
      name: "Contract",
      component: () =>
        import(/* webpackChunkName: "login" */ "./views/Contract.vue"),
    },
  ],
});
