<template>
  <div id="app">
    <router-view />
    <el-dialog title :visible.sync="$store.state.liveSelect.show" :lock-scroll="false">
      <p style="text-align:center; margin-bottom:20px; font-size:20px; font-weight:bolder;">选择打开方式</p>
      <div style="text-align:center;">
        <el-button type="primary" round style="width:200px;" @click="openWeb">网页</el-button>
        <el-button type="primary" round style="width:200px;" @click="openClient">客户端</el-button>
      </div>
    </el-dialog>
    <Service v-if="$route.name !='OrderConfirm' && $route.name !='OrderPay' && $route.name !='Course'" ref="service"/>
  </div>
</template>
<script>
import Service from "@/components/Service.vue";
export default {
  name: "App",
  components: {
    Service
  },
  async created() {
    this.$store.dispatch("get_user_info", this.$router);
    this.$store.dispatch('getWebConfig', this.$route.meta.title || '');
    let data = await this.$http.get(
      "/api/contract/get?params=customer_chat_contract"
    );
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.text = data.customer_chat_contract;
    document.body.appendChild(script);
  },
  methods: {
    openWeb() {
      window.open(this.$store.state.liveSelect.web_url);
      this.$store.commit("showSelectLive", { show: false });
    },
    openClient() {
      const { href } = this.$router.resolve({
        name: "DownloadClient",
        query: {
          url: this.$store.state.liveSelect.client_url
        }
      });
      window.open(href);
      this.$store.commit("showSelectLive", { show: false });
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/css/common.scss";
#app {
  height: 100%;
}
</style>
