import Vue from 'vue';

/**
 * 进入直播间
 * @param {String|Number} course_id 课程id
 * @param {String|Number} course_type 课程类型
 * @param {String|Number} period_id 学时id，公开课为0，一对一省略
 * @returns {void}
 */
Vue.prototype.$livePlay = async function(course_id, course_type, period_id) {
    let liveData;
    if (course_type == 1) {
        liveData = await this.$http.get(
            "/api/app/oto/getLiveRoomCode/" + course_id + "/0"
        );
    }
    if (course_type == 2 || course_type == 3 || course_type == 4) {
        let data = await this.$http.get(
            "/api/app/getPcRoomCode/course_id=" +
            course_id +
            "/chapter_id=" +
            period_id
        );
        liveData = data.chapterInfo;
    }
    if (liveData) {
        if (this.$store.state.commonSetting.watch_live_type == 1) {
            window.open(liveData.web_url);
        } else if (this.$store.state.commonSetting.watch_live_type == 2) {
            if (liveData.client_url) {
                const { href } = this.$router.resolve({
                    name: "DownloadClient",
                    query: {
                        url: liveData.client_url
                    }
                });
                window.open(href);
            } else {
                window.open(liveData.web_url);
            }
        } else if (this.$store.state.commonSetting.watch_live_type == 3) {
            if (liveData.client_url) {
              this.$store.commit("showSelectLive", { show: true, client_url: liveData.client_url, web_url: liveData.web_url });
            } else {
                window.open(liveData.web_url);
            }
        }
    }
};
