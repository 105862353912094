<template>
  <div id="service">
    <div class="service" @click="showService">
      <img class="serviceIcon" src="~@/assets/images/icon_service.png" />
      <p>客服</p>
    </div>
    <el-dialog
      class="dialog-consult"
      title="客服咨询"
      :visible.sync="dialogChat"
      width="800px"
      center
    >
      <img :src="chatUrl" v-if="dialogChat" style="width:100%" />
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: "service",
  data() {
    return {
      dialogChat: false,
      // chatUrl:
      // "https://zgjywx.oss-cn-shenzhen.aliyuncs.com/customer-service.jpg"
      chatUrl: "https://work.weixin.qq.com/kfid/kfcff55f4dc317ef5ee"
    };
  },
  methods: {
    showService() {
      // this.dialogChat = true;
      window.open(this.chatUrl, "_blank");
    }
  }
};
</script>

<style scoped lang="scss">
#service {
  position: fixed;
  right: 0;
  bottom: 100px;
  z-index: 999999999;
}
.service {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column;
  width: 104px;
  height: 86px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 14px 0px rgba(155, 155, 155, 0.42);
  border-radius: 50px 0px 0px 50px;
  cursor: pointer;
  p {
    color: #333333;
  }
}
.serviceIcon {
  display: block;
  width: 28px;
  height: 43px;
}
.dialog-consult {
  /deep/ {
    .el-dialog {
      margin-top: 30px !important;
    }
    .el-dialog__body {
      padding: 5px;
    }
  }
}

#frame {
  display: block;
  height: 600px;
  width: 100%;
}
</style>
